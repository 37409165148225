// SLIDER CHIFFRES

import Swiper from "swiper";

// core version + navigation, pagination modules:
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  Lazy,
} from "swiper/core";

// configure Swiper to use modules
SwiperCore.use([Navigation, Autoplay, Pagination, Lazy]);

// INIT
const initSliderChiffres = (el) => {
  new Swiper(el, {
    loop: false,
    lazy: {
      loadPrevNext: true,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 3000,
      pauseOnMouseEnter: true,
    },
    on: {
      init: function () {
        // for each image in el, remove attributes sizes*
        // FIX DES IMAGES EN COVER SUR EDGE PAR EXEMPLE / webkit etc.
        let images = Array.from(el.querySelectorAll("img"));
        images.forEach(function (item) {
          item.removeAttribute("sizes");
        });
      },
    },
  });
};

(function ($) {
  "use strict";

  // ATTACH
  Drupal.behaviors.memoire_home_slider_chiffres = {
    attach: function (_context) {
      $(".home-chiffres-data").once("swiper-init", function () {
        initSliderChiffres($(this)[0]);
      });
    },
  };
})(jQuery);
