
(function($) {
  Drupal.behaviors.ssks_menu_mobile = {
      initOk: false,

      attach: function(context, settings) {
          // eviter le rappel via AJAX
          if (Drupal.behaviors.ssks_menu_mobile.initOk) return;
          Drupal.behaviors.ssks_menu_mobile.initOk = true;

          Drupal.behaviors.ssks_menu_mobile.menuMobile();
      },

      /*---------------------------------------------------------------------
      :: MENU MOBILE
      -------------------------------------------------------------------*/
      menuMobile: function() {

          // TOUS LES LIENS OU INPUT DU MENU MOBILE
          var links = $('#menu-mobile-container').find('a:not(.deplier-bouton), input');

          links.each(function(){
              $(this).focus(function(){
                  openMenu();
              })
          });

          // OUVERTURE DU MENU
          function openMenu() {
              if ($('#menu-mobile-container').hasClass('cbp-spmenu-open')) return;

              $('#menu-mobile-bouton').addClass('active');
              $('body').addClass('cbp-spmenu-push-toright');
              $('#menu-mobile-container').addClass('cbp-spmenu-open');

              // focus sur le premier input ou lien
              setTimeout(function(){
                   $(links[0]).focus();
              }, 20);
          }


          // FERMETURE DU MENU
          function closeMenu() {
              $('#menu-mobile-bouton').removeClass('active');
              $('body').removeClass('cbp-spmenu-push-toleft cbp-spmenu-push-toright' );
              $('.cbp-spmenu-open').removeClass('cbp-spmenu-open' );
          }


          // click toggle menu bouton
          $('#menu-mobile-bouton').on('click', function(event) {
              
              if ( $(this).hasClass('active')){
                  closeMenu();
              } else {
                  openMenu();
              }
              event.stopPropagation();
          });

          // Replie menu mobile au clic sur le container du site
          $('.overlay-mobile, #menu-mobile-close-bouton').on("click", function(){
              closeMenu();
              $('#menu-mobile-bouton').focus();
          });

          // Stop la propagation du clic pour éviter le repli du menu mobile
          $('#menu-mobile-container').on('click', function(event) {
              event.stopPropagation();
          });

          // Ferme le menu quand on "echap"
          $(document).unbind('keyup').keyup(function(e) {
              if (e.keyCode == 27) { // escape key maps to keycode `27`
                  closeMenu();
                  $('#menu-mobile-bouton').focus();
              }
          });  

          // Ferme le menu FOCUS SUR UN LIEN HORS MENU
          $('a:not(#menu-mobile-bouton), button:not(#menu-mobile-bouton), input').on('focus', function(){

              if($(this).closest('#menu-mobile-container').length == 0 ){
                  closeMenu();
              }
              
          });

          // Initialise le sous menu et l'actif sur le bouton déplié si la rubrique est active
          $('.menu-mobile li.active ul ').show();
          $('.menu-mobile li.active .deplier-bouton').addClass('active');

          // Bouton deplie le sous menu mobile
          $('.deplier-bouton').each(function() {
              if ($(this).closest('li').children('ul').length > 0) {

                  $(this).click(function() {
                      $(this).toggleClass('active');
                      $(this).closest('li').children('ul').slideToggle();
                      return false;
                  });

              } else {
                  $(this).hide();
              }

          });
      }
  }
})(jQuery) ;
