import '@fancyapps/fancybox';


// SWUP FIX,
// retire le déclenchement du history back
jQuery.fancybox.defaults.hash = false;

jQuery('[data-fancybox="images"]').fancybox({
  buttons: [
    // "zoom",
    // "share",
    // "slideShow",
    // "fullScreen",
    // "download",
    // "thumbs",
    "close"
  ]
});

