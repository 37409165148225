import "ion-rangeslider";

(function ($) {
    "use strict";

    function initSlider(el) {
        var debut = $("input[name=dates_debut]").attr("value");
        var fin = $("input[name=dates_fin]").attr("value");

        $(el).attr("value", debut + ";" + fin);

        var $btnSubmit = $(el).closest("form").find(".submitter");
        $(el).ionRangeSlider({
            type: "double",
            skin: "round",
            prettify_enabled: false,
            onFinish: function (data) {
                updateValues(data);

                window.setTimeout(function () {
                    $btnSubmit.trigger("mousedown");
                }, 300);
            },
        });
    }

    function updateValues(data) {
        $("input[name=dates_debut]").attr("value", data.from);
        $("input[name=dates_fin]").attr("value", data.to);
    }

    // ATTACH
    Drupal.behaviors.range_slider = {
        attach: function (_context) {
            $(".js-range-slider").once("range-init", function () {
                initSlider(this);
            });
        },
    };
})(jQuery);
