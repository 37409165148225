(function($) {
  "use strict";

  // ATTACH
  Drupal.behaviors.filtres = {
    attach: function(_context) {


       $('[id*=fitre_field_ciclic_memoire_liste_films_global]').once(function(){
        
          $(this).on("keyup", function(event) {
            if (event.keyCode === 13) {
              event.preventDefault();
              $('.button-recherche-global').trigger('mousedown');
            }
        });
      });


    }
  };

})(jQuery);

