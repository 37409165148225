// SLIDER CHIFFRES

import Swiper from "swiper";

// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Autoplay, Lazy } from "swiper/core";

// configure Swiper to use modules
SwiperCore.use([Navigation, Autoplay, Lazy]);

// INIT
const initSliderChiffres = (el) => {
  new Swiper(el, {
    loop: false,
    navigation: {
      nextEl: ".memoire-home-archives-next",
      prevEl: ".memoire-home-archives-prev",
    },
    lazy: {
      loadPrevNext: true,
    },

    slidesPerView: 1,
    breakpoints: {
      // when window width is >= 576px
      576: {
        slidesPerView: 2,
      },
      // when window width is >= 768px
      768: {
        slidesPerView: 3,
      },
      // when window width is >= 992px
      992: {
        slidesPerView: 4,
      },
      // when window width is >= 1200px
      1200: {
        slidesPerView: 6,
      },
    },
    on: {
      init: function () {
        // for each image in el, remove attributes sizes*
        // FIX DES IMAGES EN COVER SUR EDGE PAR EXEMPLE / webkit etc.
        let images = Array.from(el.querySelectorAll("img"));
        images.forEach(function (item) {
          item.removeAttribute("sizes");
        });
      },
    },
  });
};

(function ($) {
  "use strict";

  // ATTACH
  Drupal.behaviors.slider_home_slider_archives = {
    attach: function (_context) {
      $(".swiper-memoire-liste-films-archives").once(
        "swiper-init",
        function () {
          initSliderChiffres($(this)[0]);
        }
      );
    },
  };
})(jQuery);
