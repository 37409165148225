(function($){
	Drupal.behaviors.ssks_loader = {
		attach: function (context, settings) {

			// ACTIVE le loader que sur la carte mémoire... :(
			// ceci à cause des filtres en autocomplete ex : annuaire livre,
			// qui ne déclenchent pas l'ajaxStop() ex :  /publications-en-region/publications-des-auteurs-et-editeurs-en-region
			if($('#filtres_liste_films_archives').length){
				Drupal.behaviors.ssks_loader.ssksLoader();
			}

		},

		ssksLoader: function () {
			var loaderElt =
				'<div id="ssks-loader" class="ajax-progress ajax-progress-throbber hidden">\
					<div class="throbber"></div>\
				</div>';

			if ($("#ssks-loader").length) return;

			//Creation de l'element
			$(".website-container").once('initloader', function(){
				$(this).append(loaderElt);
			});
		},
		ssksLoaderStart: function(){
			$("#ssks-loader").removeClass("hidden");
		},
		ssksLoaderEnd: function(){
			$("#ssks-loader").addClass("hidden");
		},
	};

	$(document)
		.ajaxStart(function (e) {
			Drupal.behaviors.ssks_loader.ssksLoaderStart();
		})
		.ajaxStop(function (e) {
			Drupal.behaviors.ssks_loader.ssksLoaderEnd();
		});
	$(document).ajaxError(function (e) {
		Drupal.behaviors.ssks_loader.ssksLoaderEnd();
	});
})(jQuery);//jQuery wrapper
