(function($) {
  "use strict";


  function appendSvgCiclic($elt){
    var SVG = `<svg xmlns="http://www.w3.org/2000/svg" class="svg-ili" width="174" height="109" viewBox="0 0 174 109">
                <g id="Groupe_1" data-name="Groupe 1" transform="translate(-1008 -551)">
                  <rect id="Rectangle_1" data-name="Rectangle 1" width="16" height="58" transform="translate(1040 579)" />
                  <rect id="Rectangle_2" data-name="Rectangle 2" width="16" height="58" transform="translate(1133 579)" />
                  <rect id="Rectangle_3" data-name="Rectangle 3" width="16" height="84" transform="translate(1102 553)" />
                  <circle id="Ellipse_1" data-name="Ellipse 1" cx="10" cy="10" r="10" transform="translate(1038 551)" />
                  <circle id="Ellipse_2" data-name="Ellipse 2" cx="10" cy="10" r="10" transform="translate(1131 551)" />
                  <circle id="Ellipse_3" data-name="Ellipse 3" cx="10" cy="10" r="10" transform="translate(1070 640)" />
                  <circle id="Ellipse_4" data-name="Ellipse 4" cx="10" cy="10" r="10" transform="translate(1008 640)" />
                  <circle id="Ellipse_5" data-name="Ellipse 5" cx="10" cy="10" r="10" transform="translate(1162 640)" />
                </g>
              </svg>`;
    
    $elt.append(SVG);

  }

  function fixCiclicClasses(){
    $('a.outil-recherche').addClass('menu-fa-search');
    $('a.outil-contact').addClass('menu-fa-envelope');
    $('a.outil-actualites').addClass('menu-fa-calendar');
    $('a.outil-recherche').addClass('menu-fa-search');
    $('a.outil-facebook').addClass('menu-fa-facebook ');
    $('a.outil-sociaux').addClass('menu-fa-facebook ');
    $('a.outil-sites').addClass('menu-fa-globe ');
    $('a.outil-ressources').addClass('menu-fa-folder-open ');
    $('a.outil-ressources-site').addClass('menu-fa-folder-open ');
    $('a.outil-pro').addClass('menu-fa-assistive-listening-systems ');
    $('a.outil-dossier').addClass('menu-fa-bookmark');
  }
  /*---------------------------------------------------------------------
  :: Ajoute une icone dans un lien de menu <a>
  ---------------------------------------------------------------------*/
  function menuIcones(){


    $('a.outil-ciclic').once('svg', function(){
      appendSvgCiclic($(this));
    });


    $('a[class*="menu-fa"], a[class*="menu-zmdi-"]').once('iconised', function(){
            
      $(this).each(function(i, el){
        
        // $(this).addClass('i-processed');
        
        var menuClass = (el.className.match(/(^|\s)(menu\-(fa|zmdi|fal)\-[^\s]*)/) || [,,'']) ;
        var fontClass = menuClass[2].replace('fal', 'fa');
        var fontPrefix = menuClass[3];
        if(fontClass){
          var iconeClass = fontClass.substr('menu-'.length);
          var icone = '<i class="'+ fontPrefix +' '+ iconeClass +'"></i>';
          $(this).prepend(icone);
        }
      });
    });
  }

  // ATTACH
  Drupal.behaviors.menu_icones = {
    attach: function(_context) {
      fixCiclicClasses();
      menuIcones();
    }
  };

})(jQuery);
