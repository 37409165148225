import { createPopper } from '@popperjs/core';

(function($) {
  "use strict";

  let root = document.documentElement;
  let menuDepliants = '.menu-depliant';
  let mainMenuLinks = '.main-menu a';
  let currentScroll = 0;
      
  // CACHE AVEC TIMER
  var timerMenuOut ;


  const updateCurrentScrollPostion = () => {
    currentScroll = $(window).scrollTop();
  }

  // Get class
  function getClass(classes, regex) {
    var arr = classes.split(" ");
    var res = arr.find(function(el) {
        return el.match(regex)
    })
    return res;
 }


 const checkToolbarHeight = () => {
  if($('.toolbar-drawer').length){
    var toolbarHeight = window
    .getComputedStyle($('.toolbar-drawer')["0"], null)
    .getPropertyValue("padding-top");

    // Set var dans le root
    root.style.setProperty("--toolbar-height" , toolbarHeight);
  }
}


 const getWebsiteHeaderHeight = () => {
    if($('.website-header-desktop').length){

      var propertyValue = window
      .getComputedStyle($('.website-header-desktop')["0"], null)
      .getPropertyValue("height");

      // Set var dans le root
      root.style.setProperty("--website-header-desktop-height" , propertyValue);
    }

 }

  //SHOW
  const showMenuDepliant = ($menu, $lien) => {

    $lien.removeClass('not-hover').addClass('is-hover');
    $(mainMenuLinks).not($lien).removeClass('is-hover').addClass('not-hover');

    $menu.css({
      'z-index':34
    });

    $menu.show();
    updateCurrentScrollPostion();
  }

  //HIDE
  const hideMenuDepliant = ($menu = $('.menu-depliant')) => {

    $(mainMenuLinks).removeClass('is-hover not-hover');

    $menu.css({
      'z-index':33
    });
    $menu.hide();
  }



    // On attend le chargement des polices
    $( function() {
        document.fonts.ready.then(function () {

            $(mainMenuLinks).on('mouseover', function(){
                var $pole = getClass(jQuery(this).attr("class"), /pole-/);
                var $targetMenu = $(menuDepliants + '[data-pole="'+$pole+ '"]');

                hideMenuDepliant($(menuDepliants).not($targetMenu));
                showMenuDepliant($targetMenu, $(this));
            });

            $('.menu-depliant-close').on('click', function(){
                hideMenuDepliant();
            });

            getWebsiteHeaderHeight();
            checkToolbarHeight();

            $(mainMenuLinks + ' , '+ menuDepliants).on('mouseover', function(){
                clearTimeout(timerMenuOut);
            });

            $(mainMenuLinks + ' , ' + menuDepliants).on('mouseleave', function(){
                timerMenuOut = setTimeout(function(){hideMenuDepliant()}, 1000);
            });
        });
    });

  $(window).on('resize', function(){
    getWebsiteHeaderHeight();
    checkToolbarHeight();

  });

  var lastScrollTop = 0;

  // CACHE LE MENU AU SCROLL
  $(window).on('scroll', function(){

    // if (currentScroll - $(window).scrollTop()  <= -240){
    //   hideMenuDepliant();
    // }

    var st = $(window).scrollTop();
    if(st >= lastScrollTop){
      hideMenuDepliant();
    }

    lastScrollTop = st;

  });

})(jQuery);
