import { createPopper } from '@popperjs/core';

(function($) {
  "use strict";



  const initSousmenu = () => {

    $('body').addClass('has-sous-menu');
  
    const itemMenu = document.querySelector('.main-menu-active');
    const sousMenu = $('#sous-menu');
    
    createPopper(itemMenu, sousMenu[0], {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 30],
          },
        },
      ],
    
    });
  
  }

    
  const cloneSousMenuMobile = () => {

    let sousMenuItems = '';
    $('#sous-menu').find('a').each(function(){
      const href = $(this).attr('href');
      const text = $(this).text();

      sousMenuItems += `<a href="${href}" class="enfants-niveau-3 h4 text-decoration-none d-flex couleur-texte mb-3">
                          <span class="main-menu-enfants-icone">
                            <i class="fal fa-arrow-right"></i></span>
                          <span class="l-hover fw-bold">
                            ${text}
                          </span>
                        </a>`;
    });

    const sousMenu = `<nav id="sous-menu-mobile" class="sous-menu-mobile main-menu-enfants d-lg-none">
                        ${sousMenuItems}
                      </nav>`;
    
    $('.node-content-padding').append(sousMenu);
  }


  // Attente chargement des polices
  $( function() {
    document.fonts.ready.then(function () {
      if($('#sous-menu').length > 0 && ! $('#sous-menu a.hidden-menu:only-child').length  ){
        initSousmenu();
        cloneSousMenuMobile();
      }
    });
  });


})(jQuery);
