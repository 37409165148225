import Swiper from "swiper";

// core version + navigation, pagination modules:
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Lazy,
} from "swiper/core";

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

// CHECK IF HOME PAGE
let isHomePage = false;
var currentPath = window.location.pathname;

var homes = ["/", "/accueil-ciclic-memoire"];

if (homes.includes(currentPath)) {
  isHomePage = true;
}

// INIT
const initSliderPhotos = (el) => {
  new Swiper(el, {
    autoplay: {
      delay: 4500,
      disableOnInteraction: true,
    },
    loop: true,
    loopAdditionalSlides: 2,
    slidesPerView: "auto",
    lazy: {
      loadPrevNextAmount: 2,
      loadPrevNext: true,
    },
    // Navigation arrows
    navigation: {
      nextEl: ".slider-accueil-button-next",
      prevEl: ".slider-accueil-button-prev",
    },
    on: {
      init: function () {
        // for each image in el, remove attributes sizes*
        // FIX DES IMAGES EN COVER SUR EDGE PAR EXEMPLE / webkit etc.
        let images = Array.from(el.querySelectorAll("img"));
        images.forEach(function (item) {
          item.removeAttribute("sizes");
        });
      },
    },
  });
};

// INIT
// let containerWidth = 0;
// const checkContainerWidth = () => {

//   const containerReference = document.querySelector('#website-header .container-fluid')
//   if(containerReference){
//      containerWidth = containerReference.clientWidth
//   }

//   let root = document.documentElement;
//   root.style.setProperty("--container-width", containerWidth);

// }

// const setSliderLayout = () => {
//   checkContainerWidth();
//   console.log(containerWidth)
// }

(function ($) {
  "use strict";

  const setSliderHomeClass = (el) => {
    // Si home. Slider avec couleur de fond sur les tuiles
    // Sinon simplement couleur de texte sur le tags
    if (isHomePage && window.matchMedia("(min-width: 992px)").matches) {
      $(".slider-accueil-texte").addClass("couleur-fond");
      $(".slider-accueil-categorie").removeClass("couleur-texte");
      $(".slider-accueil-container")
        .addClass("is-home")
        .removeClass("not-home");
    } else {
      $(".slider-accueil-texte").removeClass("couleur-fond");
      $(".slider-accueil-categorie").addClass("couleur-texte");
      $(".slider-accueil-container")
        .removeClass("is-home")
        .addClass("not-home");
    }
  };

  // ATTACH
  Drupal.behaviors.slider_accueil = {
    attach: function (_context) {
      $(".slider-accueil").once("swiper-init", function () {
        setSliderHomeClass();
        initSliderPhotos($(this)[0]);
      });
    },
  };

  $(window).on("resize", function () {
    setSliderHomeClass();
  });
})(jQuery);
