(function($) {
  "use strict";

  function hideIfEmpty(){
    $('.js-hide-if-empty').each(function(){
        if ($(this).children().length == 0) {
          $(this).hide();
        }
        else {
          $(this).show();
        }
    });
  }

  
  function hideIfEmptyBreadcrumbs(){
   if( $('.breadcrumb > li').length <= 1 ){
        $('.breadcrumb').remove();
    } 
  } 


  

  // ATTACH
  Drupal.behaviors.drupal_fix = {
    attach: function(_context) {
      hideIfEmptyBreadcrumbs();
      hideIfEmpty();
    }
  };

})(jQuery);
