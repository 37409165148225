(function($) {
  "use strict";

  


  // ATTACH
  Drupal.behaviors.catalogue = {
    attach: function(_context) {


      // AJOUT LIEN SUR TABLEAUX
      $('*[data-table-hover-href]').once('catalogue-lien', function(){

        $(this).on('click', function(){
          window.location = $(this).data('table-hover-href');
          return false;
        });

      });

      // Festival
      $(".festivals-afficher").once('festivals-afficher', function(){
        
        $(this).on('click', function(){
          if( $(this).hasClass("plus")){
              $("#table-festivals td.d-none").removeClass("d-none");
              $(this).closest(".tableau-plus").addClass("d-none");
          }
        });

      });

      // Palmares
      $(".palmares-afficher").once('palmares-afficher', function(){
          if( $(this).hasClass("plus")){
              $("#table-palmares td.d-none").removeClass("d-none");
              $(this).closest(".tableau-plus").addClass("d-none");
          }
      });



      
      $('.bloc-collapse').once('bloc-collapse', function(){


            var nb_to_show = 5;
            var enfant = $(".bloc-collapse-item", this);
            var boutonAfficher = $(".afficher-bloc-collapse", this);
            var boutonMasquer = $(".masquer-bloc-collapse", this);

            boutonAfficher.hide();
            boutonMasquer.hide();

            if (enfant.length > nb_to_show) {

                enfant.eq(nb_to_show - 1).nextAll().hide();
                boutonAfficher.show();

                boutonAfficher.unbind("click").click(function() {
                    boutonAfficher.hide();
                    boutonMasquer.show();

                    enfant.eq(nb_to_show - 1).nextAll().slideDown(300);

                    return false;
                });

                boutonMasquer.unbind("click").click(function() {

                    boutonAfficher.show();
                    boutonMasquer.hide();

                    enfant.eq(nb_to_show - 1).nextAll().slideUp(300);
                    return false;
                });

                /*Ajout nombre total d'elément dans le titre*/
                $(".js-nb-element", this).append('('+ enfant.length +')')

            }

      });
        
      
    }
  }

})(jQuery);
