import Duotone from '../@ssks/duotone/Duotone';

(function($) {
  "use strict";

  let ciclicFilters =  [
    {
      id: 'bichro-livre', 
      dark: '--bs-gray-dark', 
      light: '--bs-livre'
    },
    {
      id: 'bichro-cinema', 
      dark: '--bs-gray-dark', 
      light: '--bs-cinema'
    },
    {
      id: 'bichro-patrimoine', 
      dark: '--bs-gray-dark', 
      light: '--bs-patrimoine'
    },
    {
      id: 'bichro-ciclic', 
      dark: '--bs-black', 
      light: '--bs-gray'
    },
    {
      id: 'bichro-education', 
      dark: '--bs-gray-dark', 
      light: '--bs-education'
    },
    {
      id: 'bichro-upopi', 
      dark: '--bs-gray-dark', 
      light: '--bs-upopi'
    },
  ];

  let randomFilters = [
    {
      id: 'random-bichro-yellow', 
      dark: '--bs-gray-dark', 
      light: '--random-bichro-yellow'
    },
    {
      id: 'random-bichro-peach', 
      dark: '--bs-gray-dark', 
      light: '--random-bichro-peach'
    },
    {
      id: 'random-bichro-blue', 
      dark: '--bs-gray-dark', 
      light: '--random-bichro-blue'
    },
    {
      id: 'random-bichro-mauve', 
      dark: '--bs-gray-dark', 
      light: '--random-bichro-mauve'
    },
    {
      id: 'random-bichro-orange', 
      dark: '--bs-gray-dark', 
      light: '--random-bichro-orange'
    },
    {
      id: 'random-bichro-purple', 
      dark: '--bs-gray-dark', 
      light: '--random-bichro-purple'
    },
    {
      id: 'random-bichro-green',
      dark: '--bs-gray-dark',
      light: '--random-bichro-green'
    }
  ]



  // INITIALISATION DU DUOTONE AU ATTACH
  function initDuotone() {
    $('body').once('duotone').each(function(){

      // AJout des bichro memoire si mini-site memoire
      ciclicFilters = jQuery.merge( ciclicFilters,  randomFilters)

      // Create filters on init
      const duotone = new Duotone({
        filters: ciclicFilters
      });
     
    });
  }


  
  // RANDOM DE COULEUR POUR LES FILMS DE CREATION MEMOIRE
  var colorContexts =[]
  randomFilters.forEach(filter =>{
    colorContexts.push(filter.id)
  });
  var currentColorContextId = Math.floor(Math.random() * (colorContexts.length)); // permet d'avoir une première couleur en random

  function colorContextNext(){
    if (currentColorContextId == (colorContexts.length - 1)) {
        currentColorContextId = 0;
    } else {
        currentColorContextId ++;
    }
  }
  
  Drupal.behaviors.ssks_duotone = {
    attach: function (_context) {

      //INIT
      initDuotone();

      // Ajoute la classe .fx-bichro aux films de création
      $('.block-film-creation-image-wrapper').once('init-fx-bichro-class').each(function(){
        $(this).addClass('fx-bichro');
      });


      // Si pas de pôle spécifié sur un block .fx-bichro , crée une bichro aléatoire
      // Pour les films de création de mémoire mais aussi dans Ciclic 
      $('.fx-bichro').not('[data-random-bichro]').not('[class*=pole-]').once('random-bichro').each(function(){
        $(this).attr('data-random-bichro', colorContexts[currentColorContextId]);
        colorContextNext();
      });

      
    },
  };
  
  
})(jQuery);


