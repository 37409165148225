import Swiper from "swiper";

// core version + navigation, pagination modules:
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper/core";

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

// INIT
const initSliderPhotos = (el) => {
  new Swiper(el, {
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
    },
    on: {
      init: function () {
        // for each image in el, remove attributes sizes*
        // FIX DES IMAGES EN COVER SUR EDGE PAR EXEMPLE / webkit etc.
        let images = Array.from(el.querySelectorAll("img"));
        images.forEach(function (item) {
          item.removeAttribute("sizes");
        });
      },
    },
  });
};

(function ($) {
  "use strict";

  // ATTACH
  Drupal.behaviors.slider_photos = {
    attach: function (_context) {
      $(".slider-photos").once("swiper-init", function () {
        initSliderPhotos($(this)[0]);
      });
    },
  };
})(jQuery);
