(function($) {
  "use strict";

  function disableBg(){
    if($('.node-type-memoire-film').length ){
      return true
    }
  }

  // CREE un calque gris au premier bloc-item rencontré.
  // Descend jusque en bas du website-container

  const css = `
    position:absolute;
    bottom:0;
    top:calc(var(--layer-bg-top) - var(--layer-body-pt));
    background:#f5f5f5;
    width:calc( var(--container-gutter-x) + var(--layer-bg-width) );
    z-index:-1;
    pointer-events:none;
  `;

   const $layer = `<div id="layer-bg" class="d-none d-md-block"style="${css}"></div>`;

  const initLayer = ($el) => {
    if(!disableBg()){
      $el.css('position', 'relative');
      $el.append($layer);
    }
  }

  const updateLayer = () => {

      if(disableBg()){
        return
      }
      
      $('.aside-liste-block-container').find('.block-item').addClass('aside-liste-block-item');

      var $premierBloc = $('.block-item').not('.aside-liste-block-item').not('[class*=grand-block-item-]').not('.block-video').first();

      if( $premierBloc.length) {
        var bodyPt = window.getComputedStyle(document.body, null).getPropertyValue('padding-top');
        var topBloc = $premierBloc.offset().top;
        var largeurBloc = $premierBloc.width();

        
        document.documentElement.style.setProperty('--layer-bg-width', largeurBloc + 'px');
        document.documentElement.style.setProperty('--layer-body-pt', bodyPt);
        document.documentElement.style.setProperty('--layer-bg-top', topBloc + 'px');
      }

   }
   
   // ATTACH
   Drupal.behaviors.layer_bg = {
    attach: function(_context) {
      
      $('#website-container').once('layer-bg', function () {
        initLayer($(this));
      });

      updateLayer();


  
    }
  };

  // Resize
  $(window).on('resize', function(){
    updateLayer();
  });

  // Load
  $(window).on('load', function(){
    updateLayer();

    const resizeObserver = new ResizeObserver(entries => {
      updateLayer();
    });
    
    resizeObserver.observe(document.getElementById('website-container'));
    
  });


 

})(jQuery);
