function countChecked(el){
  var children = el.getElementsByClassName("form-checkboxes");
  var childrenArray = Array.from(children);
  var count = 0;
  childrenArray.forEach(function(el){
    count += el.querySelectorAll("input[type=checkbox]:checked").length ;
  });

  var group = el.getElementsByClassName("group_selecteurs_av");
  var groupArray = Array.from(group);
  groupArray.forEach(function(el) {
    var selects = el.querySelectorAll("select");
    var selectsArray = Array.from(selects);
    selectsArray.every(function(el) {
      if (el.value && el.value !== "_none_") {
        count++;
        // Break every()
        return false;
      }
      // Continue iterating inside every()
      return true;
    });

  });

  return count;
}

function initCheckedCounter(el){
  var target = document.querySelector(el.getAttribute("data-bs-target"));
  var span = document.createElement("span");
  var count = countChecked(target);
  // console.log(target);
  span.classList.add("filtres-counter", "badge", "rounded-pill");
  span.innerHTML = count;

  if(count){
    el.append(span);
    el.setAttribute("aria-expanded", true);
    target.classList.add("show");
  }
}


(function($) {
  "use strict";

  // ATTACH
  Drupal.behaviors.filtres_counter = {
    attach: function(_context) {

      $('#toggle_filtres_simples').once('filtres-counter').each(function(){
        // var target = $(this).attr("data-bs-target");
        // countChecked($(target));
        initCheckedCounter(this);
      });

      $('#toggle_filtres_avance').once('filtres-counter').each(function(){
        initCheckedCounter(this);
      });
    }
  };

})(jQuery);