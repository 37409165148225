
(function($) {
  Drupal.behaviors.ssks_reset_filtres = {

      attach: function() {

          // LIENS QUI déclencheront le reset
          $('.main-menu a, .menu-haut a, .menu-mobile a, [data-reset-filtres="true"]').not('[href*="?filtre"]').once('reset_filtres', function(){
              $(this).on('click', function(){
                  sessionStorage.setItem('reset_filtres','true');
              });
          });

          var mustReset = sessionStorage.getItem('reset_filtres') == 'true';
          var hasSelectActif = $('.liste-filtres option:selected').index() > 0;

            var hasInputActif = false;
            $('.liste-filtres .js-check-reset-filter .form-text').each(function(){
                if($(this).val()){
                     hasInputActif = true;
                }
            });

          var hasCheckboxActif = $('.liste-filtres .form-checkbox:checked').length > 0;
          
          if (mustReset && (hasSelectActif || hasCheckboxActif || hasInputActif ) ) {
              //  Submit du form
              $('.liste-filtres [id*=edit-reset]').not(".no-ssks-reset").trigger('mousedown');
              // Désactive le reset
              sessionStorage.setItem('reset_filtres','false');

          } else {
              sessionStorage.setItem('reset_filtres','false');
          }
          
      },

  }
})(jQuery) ;
