import Swiper from "swiper";

// core version + navigation, pagination modules:
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper/core";

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

// INIT
const initSliderPhotos = (el) => {
  new Swiper(el, {
    // Navigation arrows
    navigation: {
      nextEl: ".slider-video-next",
      prevEl: ".slider-video-prev",
    },
    pagination: {
      el: ".swiper-pagination",
    },

    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
      1440: {
        slidesPerView: 6,
      },
    },
    on: {
      init: function () {
        // for each image in el, remove attributes sizes*
        // FIX DES IMAGES EN COVER SUR EDGE PAR EXEMPLE / webkit etc.
        let images = Array.from(el.querySelectorAll("img"));
        images.forEach(function (item) {
          item.removeAttribute("sizes");
        });
      },
    },
  });
};

(function ($) {
  "use strict";

  // ATTACH
  Drupal.behaviors.slider_video = {
    attach: function (_context) {
      $(".slider-video-nav").once("swiper-init", function () {
        initSliderPhotos($(this)[0]);

        // CLICK
        $(".node-galerie-video-slider-item").on("click", function () {
          $(".video-slider-container").html(
            Drupal.settings.ssks["video_embed_" + $(this).data("nid")]
          );

          var credit_value =
            Drupal.settings.ssks["video_credit_value_" + $(this).data("nid")];
          var body_value =
            Drupal.settings.ssks["video_body_value_" + $(this).data("nid")];
          var title =
            Drupal.settings.ssks["video_title_" + $(this).data("nid")];

          $(".video-slider-description-container").show();
          $("#video-slider-copyright").html(credit_value);
          $("#video-slider-description-texte").html(body_value);
          $("#video-slider-title").html(title);

          // TOGGLE CLASS ACTIVE
          $(".node-galerie-video-slider-item").removeClass("active");
          $(this).addClass("active");

          return false;
        });
      });
    },
  };
})(jQuery);
