(function($) {
  "use strict";

  // ATTACH
  Drupal.behaviors.liste_dates = {
    attach: function(_context) {


      $('#listeDatesListe').once('liste-dates', function(){
        
        var $btnAfficherTous =  $(this).find('.js-afficher-toutes-les-dates');
        $btnAfficherTous.nextAll('.liste-dates-element').hide();
        $btnAfficherTous.on('click', function(){
          $(this).addClass('d-none');
          $(this).nextAll('.liste-dates-element').fadeIn();

          $(this).closest('.liste-dates').find('.liste-dates-element').removeClass('hidden');
        });



      });
    }
  };

})(jQuery);
