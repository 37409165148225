(function ($) {
    "use strict";


    const moveBlockToBottom = () => {

      const $asideContainer = $('[data-js-aside-container]');
      const $bottomContainer = $('[data-js-bottom-container]');
      const $item = $('.block-item');
      const $asideList = $asideContainer.find('.node-liste-container');

      

      // BOUGE TOUTE LA LISTE pour maintenir le fonctionnement de la pagination

      if(Drupal.settings.node_liste_contenu_affichage){
        $asideList.appendTo($bottomContainer);
      }

      // SI plus de 6 elt dans la liste
      else if( $asideList.find($item).length >= 6 ){
        $asideList.appendTo($bottomContainer);
      }

      // AJOUTE LES BLOCS RESTANT EN DEBUT DE COLONNE
      // SI plus de 6
      if($asideContainer.find($item).length >= 6 ){
        $asideContainer.find($item).prependTo($bottomContainer);
      }

      const $asideHeight = $('.node-aside').height() - $asideContainer.height();
      const $contentHeight = $('.node-content').height() - $asideContainer.height();

      if($asideHeight > ($contentHeight + $item.height())){
        $asideContainer.find($item).prependTo($bottomContainer);
      }
      $("[data-js-bottom-container] .block-item").removeClass('aside-liste-block-item');

    }


    Drupal.behaviors.colonne_contenu = {
      attach: function(_context) {
        moveBlockToBottom();
      }
    };
  

})(jQuery);
