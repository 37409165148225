(function ($) {
  "use strict";

  var headerID = "website-header";
  var toolbarID = "toolbar";
  var enableShadow = true;
  var headerZindex = 30;
  var delay = 300;

  // SCROLL
  var isScrolling = false;

  function initHeaderStyle() {
    $("#" + headerID).css({
      transition: "." + delay + "s",
      position: "static",
      "box-shadow": enableShadow ? "rgba(255,255,255,0)" : "none",
    });
  }

  function addShadow() {
    if (enableShadow) {
      $("#" + headerID).css({
        "box-shadow": "0 0 40px rgba(0,0,0,.15)",
      });
    }
  }

  function removeShadow() {
    if (enableShadow) {
      $("#" + headerID).css({
        "box-shadow": "0 0 0 rgba(0,0,0,0)",
      });
    }
  }

  var lastScrollTop = 0;
  var scrollMoreThanHeader = false;

  function scrollAction() {
    var headerHeight = document.getElementById(headerID)
      ? document.getElementById(headerID).offsetHeight
      : 0;
    var toolbarHeight = document.getElementById(toolbarID)
      ? document.getElementById(toolbarID).offsetHeight
      : 0;

    var scrollDirection = "down";
    var scrollDirection = false;
    var st = $(window).scrollTop();
    var isDesktop = window.matchMedia("(min-width: 992px)").matches;

    // Dépassé le header ?
    if ($(window).scrollTop() > headerHeight + toolbarHeight + 50) {
      scrollMoreThanHeader = true;
    } else {
      scrollMoreThanHeader = false;
    }

    //DIRECTION
    if (st >= lastScrollTop) {
      scrollDirection = "down";
    } else {
      scrollDirection = "up";
    }

    lastScrollTop = st;

    let hasScrolled = false;

    if (scrollDirection == "down" && (scrollMoreThanHeader || hasScrolled)) {
      $("#" + headerID).css({
        top: 0,
        background: "rgba(255,255,255,.97)",
        transition: "." + delay + "s",
        transform: "translateY(-100%)",
      });

      removeShadow();

      setTimeout(function () {
        $("#" + headerID).css({
          position: "sticky",
        });
      }, 300);
    } else if (scrollDirection == "up") {
      $("#" + headerID).css({
        position: "sticky",
        background: "rgba(255,255,255,.97)",
        top: toolbarHeight,
        "z-index": headerZindex,
        transition: "." + delay + "s",
        transform: "translateY(0)",
      });

      if (st > 0) {
        addShadow();
        $("#" + headerID).addClass("is-shadowed");
      } else {
        removeShadow();
        $("#" + headerID).removeClass("is-shadowed");
      }
    }

    // SCROLL
    if (st == 0) {
      initHeaderStyle();
    }

    isScrolling = false;
  }

  // FIX TOOLBAR HEIGHT
  function fixToolbarHeight() {
    if (document.getElementById(toolbarID)) {
      var toolbarHeight = document.getElementById(toolbarID).offsetHeight;
      setTimeout(() => {
        $("body").css("padding-top", toolbarHeight + "px");
      }, 60);
    }
  }

  // INIT HEADER STYLE
  initHeaderStyle();

  Drupal.behaviors.header_fix = {
    attach: function (_context) {
      $("body:not(.node-type-memoire-film)").once(
        "fix-toolbar-height",
        function () {
          fixToolbarHeight();
          $(window).on("load", function () {
            fixToolbarHeight();
          });

          $(window).on("resize", function () {
            fixToolbarHeight();
          });

          $(window)
            .on("scroll", function () {
              if (!isScrolling) {
                isScrolling = true;
                window.requestAnimationFrame =
                  window.requestAnimationFrame ||
                  window.mozRequestAnimationFrame ||
                  window.webkitRequestAnimationFrame ||
                  window.msRequestAnimationFrame;
                if (window.requestAnimationFrame)
                  window.requestAnimationFrame(scrollAction);
                else scrollAction();
              }
            })
            .trigger("scroll");
        }
      );
    },
  };
})(jQuery);
