(function($) {
  "use strict";

  // ATTACH
  Drupal.behaviors.film_partage = {
    attach: function(_context) {

      $('[data-copy-target]').once('copy-init').each(function(){

        $(this).on('click', function(e){
          e.preventDefault();
  
          var parentForm = $(this).closest('.dropdown-partage-contenu');
          var targetId = $(this).data('copy-target');
          parentForm.find('[data-copy-id='+ targetId +']').select();
  
          document.execCommand("copy");
  
          return false;
        });
      })

    }
  };

})(jQuery);

