import "@popperjs/core";
import { Tooltip, Collapse } from "bootstrap";
const bootstrap = (window.bootstrap = require("bootstrap"));

/* -------------------------------- tooltips -------------------------------- */
const initTooltip = () => {

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
}

// ATTACH
Drupal.behaviors.ssks_bootstrap = {
  attach: function (_context) {
    initTooltip();

  },
};



