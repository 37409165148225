
function toggleText(buttonEl, textEl){
  if (textEl.classList.contains("visible-text")){
    textEl.classList.remove("visible-text");
    textEl.classList.add("collapsed-text");
    buttonEl.innerHTML = "Lire plus"
  } else {
    textEl.classList.add("visible-text");
    textEl.classList.remove("collapsed-text");
    buttonEl.innerHTML = "Lire moins"
  }
}

function initToggleText(textEl){
  var button = document.createElement("button");
  button.classList.add("read-more-less", "btn", "btn-outline-primary")
  button.innerHTML = "Lire plus";

  if (textEl.offsetHeight < textEl.scrollHeight ||
    textEl.offsetWidth < textEl.scrollWidth) {
    // overflow. On affiche le bouton "lire plus"
    textEl.classList.add("collapsed-text");
    textEl.after(button);

    // Init Listener
    button.addEventListener("click", function(e){
      e.preventDefault()
      toggleText(this, textEl);
    })
  }

}


(function($) {
  "use strict";

  // ATTACH
  Drupal.behaviors.readmore = {
    attach: function(_context) {

      $('.node-film-texte').once('read-more-init').each(function(){
        initToggleText(this);
      })


    }
  };

})(jQuery);