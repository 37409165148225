(function($) {
  "use strict";



    function embedResponsive(){
      // fix si iframe mise dans le wysiwyg sans fonction media
      $('.mceContentBody iframe').each(function(){
         if($(this).closest('.media_embed').length == 0) {
             $(this).wrap('<div class="media_embed" />');
         }
      });

        var iframeResponsive =  $(".media_embed iframe");


      if ( iframeResponsive.length > 0  ) {

          iframeResponsive.not('.resp-processed').each(function(){

              $(this).addClass('resp-processed');


              // FIX EMBED mémoire avec width en 100%
              // Certianement importé automatiquement
              if($(this).is($('[src^="/embed/"]')) && $(this).attr('width') == "100%"){
                $(this).attr('width', 640);
              }


              var iframeAttrWidth = $(this).attr('width').replace('px','');
              var iframeAttrHeight = $(this).attr('height').replace('px','');

              if(iframeAttrHeight == ''){
                $(this).attr('height', 480) 
              }
        
              var largeurIframe = (iframeAttrWidth != '100%' ) ? iframeAttrWidth : '640'; 
              var hauteurIframe = iframeAttrHeight ? iframeAttrHeight : '480';

              //  largeurIframe =  largeurIframe $(this).width();
              var ratio = hauteurIframe / largeurIframe * 100;


              $(this).closest('.media_embed, .embed-audio').addClass('responsive').css({
                  'padding-top' : ratio +'%',
                  "position": "relative",
                  "margin":"2.2rem 0 2.2rem",
                  "border-radius":".5rem",
                  "overflow":"hidden",
                  "display":"block",
                  "width" : "100%" ,
                  "height": "auto !important"
              });

              $(this).css({
                "position":"absolute",
                "top":"0",
                "left":"0",
                "width":"100%",
                "height":"100%",
                "background":'var(--bs-gray-200)'
              });

          });
      }
    }
  

  // ATTACH
  Drupal.behaviors.embed_responsive = {
    attach: function(_context) {
      embedResponsive();
    }
  };

})(jQuery);
