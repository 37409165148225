(function($) {
  "use strict";


  const icones =[ 
    '',
    'fal fa-book',/* publications */
    'fal fa-paper-plane',/* newsletter */
    'fal fa-user-circle',/* personnage   */
    'fal fa-file-search',/* recherche */
    'fal fa-address-book',/* annuaire */
    'fal fa-file-alt',/* communiqués */
    'fal fa-file-download',/* telechargement publication */
    'fab fa-facebook-square',/* facebook */
    'fab fa-twitter',/* twitter */
    'fal fa-external-link-square',/* lien-externe  */
    'fal fa-plus-circle',/* plus  */
    'fal fa-file-download',/* télechargement  */
    'fal fa-arrow-square-right',/* lien-interne   */
    'far fa-calendar-alt',/* Calendrier   */
    'fal fa-map-marker-alt',/* Geo-Calendrier   */
    'fal fa-external-link-square',/* Partenaires    */
    'fal fa-play',/* videos   */
    'fal fa-globe',/* Reseau sociaux   */
  ];


  const initBlockIcones = ($elt)=> {
    const typeRessources = $elt.data('type-ressource')
 
    if(typeRessources != undefined){
      $elt.find('.block-texte-wrapper').append('<i class="'+ icones[typeRessources] +' fa-2x icone-ressource mt-2" ></i>');
    }
  }

  Drupal.behaviors.block_icones = {
    attach: function(_context) {

      $('.block-ressources').once('iconized', function(){
        initBlockIcones($(this));
      });

    }
  };

})(jQuery);
