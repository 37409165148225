(function($) {
  "use strict";



  function crop_text($el) {
      var $texteWrapper = $el.find('.block-texte-content');
      
      $el.find('.block-resume + .block-resume').remove();
      var $resume = $el.find('.block-resume').eq(0);
    

      // echappe si pas de texte content
      if ($texteWrapper.length == 0  || $resume.text() == "" || $resume.length == 0) {
        return;
      }

      let count = 0;
      let parentWidth = $texteWrapper[0].offsetWidth;
      let parentHeight = $texteWrapper[0].offsetHeight;
      
      do {
        count++;
      
        parentWidth = $texteWrapper[0].offsetWidth;
        parentHeight = $texteWrapper[0].offsetHeight;
      
        if ( parentWidth < parentHeight ){
            let str = $resume.text();
            let lastIndex = str.lastIndexOf(" ");
            str = str.substring(0, lastIndex);
            str = str.replace('…','').trim();
            $resume.html(str + '...')
          parentHeight = $texteWrapper[0].offsetHeight;
        }
      } while (
        count < 20 && parentWidth < parentHeight
      );
   
  }

  Drupal.behaviors.block_resume = {
    attach: function(_context) {
      document.fonts.ready.then(function () {
        $('.block-item').once('text-croped-loaded', function(){
          crop_text($(this));
        });
      });
    }
  };



  
})(jQuery);
